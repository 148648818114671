export default {
  Operator: 'Оператор',
  Password: 'Пароль',
  Login: 'Войти',
  Authentication: 'Аутентификация',
  Message: 'Сообщение',
  Messages: 'Сообщения',
  MessageInvite: 'Прошу подойти ко мне',
  Chat: 'Внутренний чат',
  Chat_list: 'Список',
  type_message: 'Ваше сообщение',
  addHref: 'Добавить ссылку на регистрацию',
  Logout: 'Выйти',
  Fullscreen: 'На весь экран',
  unFullscreen: 'Назад',
  Current_list: 'Текущий список',
  Current_queue: 'Текущая очередь',
  Deferred: 'Отложенные',
  Leaf_waiting: 'Лист ожидания',
  Leaf_productions: 'Лист постановки',
  FIO: 'ФИО',
  Package_service_record_queue: 'Пакет, услуга, запись, очередь',
  Ticket_time: 'Талон, время',
  Phone_email_viber: 'Телефон, email, Viber',
  Action: 'Действие',
  Add: 'Добавить',
  Nearest: 'На ближайшее время',
  Ticket: 'Талон',
  Automatic: 'Автомат',
  Options: 'Опции',
  Without_invitation: 'Без приглашения',
  Without_notification: 'Без уведомлений сообщений',
  minutes: 'минут',
  hours: 'часов',
  seconds: 'cекунд',
  shortDays: 'дн.',
  shortHours: 'ч.',
  shortMinutes: 'м.',
  shortSeconds: 'c.',
  textLost: 'Осталось:',
  Auto_call: 'Автовызов',
  Auto_start: 'Автостарт',
  Off: 'Выкл',
  Console: 'Пульт',
  Install_cancel: 'Отменить установку',
  Set_aside_min: 'Отложить на, мин',
  Move: 'Перенести',
  Close: 'Закрыть',
  Put: 'Поставить',
  Send: 'Отправить',
  Time: 'Время',
  Direction: 'Направление',
  Confirm: 'Подтвердить',
  Time_want: 'Время талона будет: ',
  Docstate: 'Услуга: ',
  Equery_num: 'Талон: ',
  Equery_date: 'Дата: ',
  Equery_time: 'Время: ',
  Ok: 'Ок',
  Print: 'Печать',
  Link_damaged: 'Ссылка повреждена!',
  Operation_successful: 'Вы записались в очередь!',
  Equery_start: 'Время: ',
  Your_name: 'Ваше имя: ',
  Service_name: 'Ваша услуга: ',
  Sunday: 'Воскресенье',
  Monday: 'Понедельник',
  Tuesday: 'Вторник',
  Wednesday: 'Среда',
  Thursday: 'Четверг',
  Friday: 'Пятница',
  Saturday: 'Суббота',
  Sun: 'Вс',
  Mon: 'Пн',
  Tue: 'Вт',
  Wen: 'Ср',
  Thu: 'Чт',
  Fri: 'Пт',
  Sat: 'Сб',
  Next: 'Следующий',
  Pause: 'Перерыв',
  Unpause: 'Из паузы',
  Back_work: 'Вернуться к работе',
  Repeated_call: 'Повторный вызов',
  Start: 'Начать',
  Finish: 'Завершить',
  Remove: 'Удалить',
  Defer: 'Отложить',
  Call: 'Вызов',
  Del: 'Удалить',
  New_client: 'Появился новый клиент!',
  New_client_communication: 'Новый клиент на общение!',
  New_message_client: 'Новое сообщение от клиента!',
  Web_console: 'WEB пульт',
  Plan_the_time: 'Спланировать время',
  Service_time: 'Время обслуживания',
  Free_time: 'Свободное время',
  Accept: 'Принять',
  By_appointment: 'По записи',
  Back: 'Назад',
  Graf_works: 'График занятости',
  buttonChart: 'Загруженность',
  chartWorkDay: 'Текущий',
  chartWorkAfter: 'Отложенные',
  chartWorkBefore: 'Обслуженные',
  chartStartDay: 'Начало дня',
  chartCurrent: 'Обслуженно',
  chartCurrentTime: 'Текущее время',
  chartEndDay: 'Конец дня',
  chartBreak: 'Перерыв'
}
